import React from 'react';
import { useSelector } from 'react-redux';
import { billTypes } from '../../enums/billTypes';
import Slider from './carouselSlider';

const BrandScroller = ({ serviceType, scrollSpeed, dialogMode }) => {
  const { staticContentUrl } = useSelector(state => state.config.applicationSettings);
  const { panelProviders, billTypes: agentBillTypes } = useSelector(state => state.config);

  const noServiceType = serviceType === billTypes.NONE;

  const hasHomeLoan = billTypes.hasServiceType(agentBillTypes, billTypes.HOME_LOAN);
  const hasSolarFinance = billTypes.hasServiceType(agentBillTypes, billTypes.SOLAR_FINANCE);

  const showFinance = hasHomeLoan && hasSolarFinance && noServiceType;
  const showHomeLoan = !showFinance && hasHomeLoan && (noServiceType || serviceType === billTypes.HOME_LOAN);
  const showSolarFinance =
    !showFinance && hasSolarFinance && (noServiceType || serviceType === billTypes.SOLAR_FINANCE);

  const showInternet =
    billTypes.hasServiceType(agentBillTypes, billTypes.INTERNET) &&
    (noServiceType || serviceType === billTypes.INTERNET);
  const showEnergy =
    billTypes.hasServiceType(agentBillTypes, billTypes.ELECTRICITY || billTypes.GAS) &&
    (noServiceType || serviceType === billTypes.ELECTRICITY || serviceType === billTypes.GAS);

  const brands = {};

  brands.energy = panelProviders
    .filter(
      p =>
        p.provider.billTypes.filter(bt => bt.id === billTypes.ELECTRICITY.Id || bt.id === billTypes.GAS.Id).length > 0
    )
    .map(e => {
      return {
        code: e.provider.code,
        url: `${staticContentUrl}/images/providers/${e.provider.logo}`
      };
    });

  brands.internet = panelProviders
    .filter(p => p.provider.billTypes.filter(bt => bt.id === billTypes.INTERNET.Id).length > 0)
    .map(e => {
      return {
        code: e.provider.code,
        url: `${staticContentUrl}/images/providers/${e.provider.logo}`
      };
    });

  brands.finance = panelProviders
    .filter(
      p =>
        p.provider.billTypes.filter(bt => bt.id === billTypes.HOME_LOAN.Id || bt.id === billTypes.SOLAR_FINANCE.Id)
          .length > 0
    )
    .map(e => {
      return {
        code: e.provider.code,
        url: `${staticContentUrl}/images/providers/${e.provider.logo}`
      };
    });

  brands.homeLoan = panelProviders
    .filter(p => p.provider.billTypes.filter(bt => bt.id === billTypes.HOME_LOAN.Id).length > 0)
    .map(e => {
      return {
        code: e.provider.code,
        url: `${staticContentUrl}/images/providers/${e.provider.logo}`
      };
    });

  brands.solarFinance = panelProviders
    .filter(p => p.provider.billTypes.filter(bt => bt.id === billTypes.SOLAR_FINANCE.Id).length > 0)
    .map(e => {
      return {
        code: e.provider.code,
        url: `${staticContentUrl}/images/providers/${e.provider.logo}`
      };
    });

  return (
    <div className={`sm-brand-scroller ${dialogMode ? 'dialog' : ''}`}>
      {showFinance && brands.finance && brands.finance.length > 0 && (
        <Slider items={brands.finance} direction="right" scrollSpeed={scrollSpeed} dialogMode={dialogMode} />
      )}
      {showHomeLoan && brands.homeLoan && brands.homeLoan.length > 0 && (
        <Slider items={brands.homeLoan} direction="right" scrollSpeed={scrollSpeed} dialogMode={dialogMode} />
      )}
      {showSolarFinance && brands.solarFinance && brands.solarFinance.length > 0 && (
        <Slider items={brands.solarFinance} direction="right" scrollSpeed={scrollSpeed} dialogMode={dialogMode} />
      )}
      {showEnergy && brands.energy && brands.energy.length > 0 && (
        <Slider items={brands.energy} direction="left" scrollSpeed={scrollSpeed} dialogMode={dialogMode} />
      )}
      {showInternet && brands.internet && brands.internet.length > 0 && (
        <Slider items={brands.internet} direction="right" scrollSpeed={scrollSpeed} dialogMode={dialogMode} />
      )}
    </div>
  );
};

export default BrandScroller;
